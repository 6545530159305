import store from '@/store/index';

export default [
    {
        title: 'dashboard',
        icon: 'home-icon',
        tagVariant: 'light-warning',
        route: 'dashboard',
    },
    {
        title: 'users',
        icon: 'side-users-icon',
        tagVariant: 'light-warning',
        route: 'users',
    },
    // {
    //     title: 'packages',
    //     icon: 'element-icon',
    //     tagVariant: 'light-warning',
    //     route: 'packages',
    // },
    {
        title: 'bookings',
        icon: 'calender-icon',
        tagVariant: 'light-warning',
        route: 'bookings',
    },
    {
        title: 'requests',
        icon: 'requests-icon',
        tagVariant: 'light-warning',
        route: 'requests',
        requests: 'true',
    },
    {
        title: 'financial_operations',
        icon: 'status-up-icon',
        tagVariant: 'light-warning',
        route: 'financial-operations',
    },
    {
        title: 'subscriptions',
        icon: 'subscriptions-icon',
        tagVariant: 'status-up-warning',
        route: 'subscriptions',
    },
    {
        title: 'chats',
        icon: 'messages-icon',
        tagVariant: 'light-warning',
        route: 'chats',
    },
    {
        title: 'coupons',
        icon: 'ticket-discount-icon',
        tagVariant: 'light-warning',
        route: 'coupons',
    },
    {
        title: 'articles',
        icon: 'article-icon',
        tagVariant: 'light-warning',
        route: 'articles',
    },
    {
        title: 'reviews',
        icon: 'star-icon',
        tagVariant: 'light-warning',
        route: 'reviews',
    },
    {
        title: 'settings',
        icon: 'setting-icon',
        tagVariant: 'light-warning',
        route: 'settings',
    },
];
